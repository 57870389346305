import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CTResultScore,
  CorrectIcon,
  Overlay,
  OverlayContent as _OverlayContent,
} from '@riddler-co-jp/specc-ui-components';
import { Rank } from '@riddler-co-jp/specc-ui-components/dist/Components/CTResultScore/RankText';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  calcScore,
  getProblemAndAnswer,
} from '../../../../lib/problems/Stage5Score';

export interface SingleSet {
  isPresenter: boolean;
  answers: SingleAnswer[];
}

export interface SingleAnswer {
  images: string[];
  answer?: number;
  answerCorrect?: boolean;
  correctUsers?: number;
}

interface Props {
  memberCount: number;
  answer: {
    isPresenter: boolean;
    answers: number[];
  }[];
  description?: string;
  rank?: Rank;
  average?: number;
}

export const ResultContents: FC<Props> = ({
  memberCount,
  answer,
  description,
  rank,
  average,
}) => {
  const { eventId } = useParams<{ eventId: string }>();
  const [modalImage, setModalImage] = React.useState<string[]>([]);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const problemAndAnswer: SingleSet[] = React.useMemo(() => {
    return getProblemAndAnswer(answer, memberCount);
  }, [answer, memberCount]);

  // 合計スコアの計算
  const score = React.useMemo(() => {
    return calcScore(problemAndAnswer, memberCount);
  }, [problemAndAnswer, memberCount]);

  return (
    <>
      <Overlay
        isVisible={showOverlay}
        onClick={() => {
          setShowOverlay(false);
        }}
      >
        {showOverlay && modalImage.length > 0 && (
          <OverlayContent>
            <QuestionWrapper>
              {modalImage.length == 1 ? (
                <img src={modalImage[0]} />
              ) : (
                <ModalImage4>
                  {modalImage.map((single, i) => {
                    return (
                      <div key={`img-${i}`}>
                        <StageNum>{i + 1}</StageNum>
                        <img src={single} />
                      </div>
                    );
                  })}
                </ModalImage4>
              )}
            </QuestionWrapper>
          </OverlayContent>
        )}
      </Overlay>

      <CTResultScore
        score={score.score}
        maxScore={score.maxScore}
        rank={rank}
        average={average}
      />

      {description != null && (
        <MissionDesc>
          <p>{description}</p>
        </MissionDesc>
      )}
      <Description>
        <h2>あなたの解答</h2>

        {problemAndAnswer.map((single, i) => {
          const score = single.answers.reduce((prev, curr) => {
            return curr.answerCorrect == true ? prev + 1 : prev;
          }, 0);
          const correctUsers = single.answers.reduce((prev, curr) => {
            if (curr.correctUsers == undefined) return prev;
            return prev + curr.correctUsers;
          }, 0);
          return (
            <SingleSet
              key={`set-${i}`}
              set={i + 1}
              score={single.isPresenter ? correctUsers : score}
              maxScore={single.answers.length}
              problem={single}
              onDisplayModal={s => {
                setModalImage(s);
                setShowOverlay(true);
              }}
              memberCount={memberCount}
            />
          );
        })}
      </Description>
    </>
  );
};

const MissionDesc = styled.div`
  width: 100%;
  background: #f5f5f5;
  margin-bottom: 30px;

  p {
    font-size: 1.6rem;
    padding: 18px 24px;
    display: block;
  }
`;

interface SetProps {
  set: number;
  score: number;
  maxScore: number;
  problem: SingleSet;
  onDisplayModal: (s: string[]) => void;
  memberCount: number;
}

const SingleSet: React.FC<SetProps> = ({
  set,
  score,
  maxScore,
  problem,
  onDisplayModal,
  memberCount,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <SetHeader
        onClick={() => {
          setOpen(!open);
        }}
      >
        <h3>セット{set}</h3>
        <div>{problem.isPresenter ? '出題者' : '解答者'}</div>
        {problem.isPresenter ? (
          <p>
            正答者数<b>{score}</b>/{(memberCount - 1) * problem.answers.length}
          </p>
        ) : (
          <p>
            スコア<b>{score}</b>/{maxScore}
          </p>
        )}
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{ transform: `rotate(${open ? '0deg' : '180deg'})` }}
        />
      </SetHeader>
      <ListWrapper style={{ maxHeight: !open ? 'initial' : '0px' }}>
        <ListHeader>
          <p>問題</p>
          <p>選択肢</p>
          {problem.isPresenter ? <p>正答者数</p> : <p>解答</p>}
        </ListHeader>

        {problem.answers.map((single, i) => {
          return (
            <SingleList key={`set-${set}-${i}`}>
              <div
                onClick={() => {
                  onDisplayModal([single.images[0]]);
                }}
              >
                <StageNum>{i + 1}</StageNum>
                <img src={single.images[0]} />
              </div>
              <div
                onClick={() => {
                  onDisplayModal([
                    single.images[1],
                    single.images[2],
                    single.images[3],
                    single.images[4],
                  ]);
                }}
              >
                <img src={single.images[1]} />
                <img src={single.images[2]} />
                <img src={single.images[3]} />
                <img src={single.images[4]} />
              </div>
              {problem.isPresenter ? (
                <PresenterAns>
                  <p>
                    <b>{single.correctUsers}</b>/{memberCount - 1}人
                  </p>
                </PresenterAns>
              ) : (
                <div>
                  {single.answer != null && single.answer > 0 ? (
                    <>
                      <div>
                        <CorrectIcon
                          state={single.answerCorrect ? 'correct' : 'incorrect'}
                        />
                      </div>
                      <p>{single.answer}</p>
                    </>
                  ) : (
                    <p style={{ opacity: 0.2, fontSize: 'initial' }}>
                      (未解答)
                    </p>
                  )}
                </div>
              )}
            </SingleList>
          );
        })}
      </ListWrapper>
    </div>
  );
};

const QuestionWrapper = styled.div`
  max-height: calc(100vh - 80px);
  overflow-y: scroll;
`;

const StageNum = styled.div`
  background: #343a40;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const OverlayContent = styled(_OverlayContent)`
  img {
    width: 600px !important;
  }

  p {
    width: 600px !important;
  }

  b {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    background: #d53220;
    padding: 2px 20px;
    margin-right: 10px;
  }
`;

const ModalImage4 = styled.div`
  width: 700px;
  display: flex;
  flex-wrap: wrap;

  > div {
    position: relative;
    display: block;
    width: 342px;
    height: auto;
    margin: 4px 4px;

    > div {
      position: absolute;
      top: 5px;
      left: 5px;
    }

    > img {
      margin: 0 !important;
      width: 100% !important;
    }
  }
`;
const Description = styled.div`
  font-size: 1.6rem;

  ul {
    margin-left: 2rem;
  }

  li {
    margin-bottom: 1.4rem;
  }

  b {
    border-bottom: 1px solid black;
  }

  h2 {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }
`;

const H3 = styled.h3`
  font-size: 2.8rem;
  margin: 20px 0;
`;

const SetHeader = styled.div`
  border: 1px solid #ced4da;
  display: flex;
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 10px;
  margin-top: 10px;
  align-items: center;
  cursor: pointer;

  h3 {
    background: #189bf2;
    color: white;
    font-weight: bold;
    font-size: 2.8rem;
    padding: 4px 18px;
    padding-bottom: 8px;
  }

  > div {
    margin-left: 20px;
    font-size: 2.6rem;
    font-weight: bold;
  }

  p {
    display: block;
    margin-left: auto;
    font-weight: bold;
    font-size: 1.8rem;
  }

  b {
    font-size: 2.8rem;
    border: none !important;
    margin: 4px;
  }

  svg {
    font-size: 2.6rem;
    margin: 0 16px;
    transition: 0.2s;
  }
`;

const QuestionLine = styled.div`
  width: 60px;
  height: 20px;
  margin: 0 6px;
  border-bottom: 1px solid #343a40;
`;

const ListWrapper = styled.div`
  border: 1px solid #ced4da;
  overflow: hidden;
  transition: 0.2s;
`;

const ListHeader = styled.div`
  display: flex;
  background: #f3f4f6;
  font-weight: bold;
  font-size: 1.8rem;
  padding: 12px;

  >p: nth-child(1) {
    margin-right: 10px;
    width: 180px;
    padding-left: 60px;
  }
  >p: nth-child(2) {
    margin-right: 10px;
    width: 200px;
  }
  >p: nth-child(3) {
    display: block;
    flex: 1;
  }
`;

const SingleList = styled.div`
  display: flex;
  position: relative;
  font-weight: bold;

  &:nth-child(2n) {
    background: #ffffff;
    img {
      border: 2px solid white;
    }
  }

  &:nth-child(2n-1) {
    background: #f3f4f6;
    img {
      border: 2px solid #f3f4f6;
    }
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0px;
    align-items: center;
  }

  >div: nth-child(1) {
   cursor: pointer;

    margin-right: 20px;
    width: 200px
    white-space: nowrap;
    padding-left: 20px;
    align-items: flex-start;

    img {
      width: auto;
      height: 80px;
    }
  }
  >div: nth-child(2) {
    cursor: pointer;

    margin-right: 20px;
    white-space: nowrap;
    width: 200px;

    display: flex;

    img {
      width: auto;
      height: 40px;
    }
  }
  >div: nth-child(3) {
    flex: 1;
    >div{
      margin-right: 10px;
    }
    >p{
      font-size: 2.6rem;
    }
  }
`;

const PresenterAns = styled.div`
  p {
    display: block;
    font-weight: bold;
    font-size: 1.8rem !important;
  }

  b {
    font-size: 2.8rem;
    border: none !important;
    margin: 4px;
  }
`;

const DocumentButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 4rem auto;
`;
