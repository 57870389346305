import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { StageId } from '../../api/stages';
import {
  StageManagerPhase,
  StageManagerState,
} from '../../components/eventProvider/stageProviders/StageManager';
import { gameActions } from '../actions/gameActions';

export interface Stage5GroupInfo {
  searchId: number;
  groupId: string;
}

export interface GameState {
  hasStageFinished: { [v: number]: boolean };
  stage4Penalty: number;
  stage5GroupInfo: Stage5GroupInfo | null;
  stage5CurrentAnswer: string | null;
  recordedStageStartTimes: Partial<Record<StageId, number>>;
  stageManagerState: StageManagerState;
}

const initState: GameState = {
  hasStageFinished: {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  },
  stage4Penalty: 0,
  stage5GroupInfo: null,
  stage5CurrentAnswer: null,
  recordedStageStartTimes: {},
  stageManagerState: {
    phase:
      process.env.REACT_APP_STAGE_DEBUG === 'true'
        ? StageManagerPhase.CONTENT
        : StageManagerPhase.INSTRUCTION,
    startButtonActive: true,
  },
};

const gameReducer = reducerWithInitialState<GameState>(initState)
  .case(gameActions.setHasStageFinished, (state, payload): GameState => {
    return {
      ...state,
      hasStageFinished: {
        ...state.hasStageFinished,
        [payload.stage]: payload.finished,
      },
    };
  })
  .case(gameActions.setStage4Penalty, (state, payload): GameState => {
    return {
      ...state,
      stage4Penalty: payload,
    };
  })
  .case(gameActions.incrementStage4Penalty, (state): GameState => {
    return {
      ...state,
      stage4Penalty: state.stage4Penalty + 1,
    };
  })
  .case(gameActions.setStage5GroupInfo, (state, payload): GameState => {
    return {
      ...state,
      stage5GroupInfo: payload,
    };
  })
  .case(gameActions.setStage5CurrentAnswer, (state, payload): GameState => {
    return {
      ...state,
      stage5CurrentAnswer: payload,
    };
  })
  .case(gameActions.setRecordedStageStartTimes, (state, payload): GameState => {
    return {
      ...state,
      recordedStageStartTimes: payload,
    };
  })
  .case(gameActions.setStageManagerState, (state, payload): GameState => {
    return {
      ...state,
      stageManagerState: {
        ...state.stageManagerState,
        ...payload,
      },
    };
  });

export default gameReducer;
