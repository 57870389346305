import * as React from 'react';
import { useDispatch } from 'react-redux';

import { finishGame } from '../../../../api/game';
import {
  useMemberDatas,
  useStage5GroupDocData,
  useStage5UserDocData,
} from '../../../../api/stage5';
import useLocalStorage from '../../../../lib/useLocalStorage';
import { gameActions } from '../../../../redux/actions/gameActions';
import { Stage5GroupInfo } from '../../../../redux/reducers/gameReducer';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import {
  useEventId,
  useStage5GroupInfo,
  useStageState,
} from '../../../../redux/selectors/gameSelectors';
import { GroupState } from '../../../../types';
import StageManager from '../../stageProviders/StageManager';
import GameManager from './GameManager';
import GroupManager from './GroupManager';
import Result from './Result';

interface Stage5Props {}
const Stage5: React.FC<Stage5Props> = () => {
  const dispatch = useDispatch();

  const eventId = useEventId();
  const stageState = useStageState({ stageId: 5 });
  const [showingGame, setShowingGame] = useLocalStorage<boolean>(
    eventId + ':showingGame',
    false
  );

  React.useState<boolean>(false);
  const groupInfo = useStage5GroupInfo();
  const user = useCurrentUser();
  const [persistentGroupInfo, setPersistentGroupInfo] =
    useLocalStorage<Stage5GroupInfo | null>(
      eventId + ':stage5GroupInfo:' + user?.uid,
      null
    );

  if (groupInfo == null && persistentGroupInfo != null) {
    dispatch(
      gameActions.setStage5GroupInfo({
        groupId: persistentGroupInfo.groupId,
        searchId: persistentGroupInfo.searchId,
      })
    );
  }

  const [groupDocData, groupDocDataLoading] = useStage5GroupDocData(
    eventId,
    groupInfo?.groupId ?? persistentGroupInfo?.groupId
  );
  const [userDocData] = useStage5UserDocData(
    eventId,
    groupInfo?.groupId,
    user?.uid
  );

  const { memberDatas, onReloadGroupMembers } = useMemberDatas(
    groupDocData,
    groupInfo,
    eventId
  );

  React.useEffect(() => {
    if (groupDocDataLoading) {
      return;
    }
    if (groupDocData === undefined) {
      setPersistentGroupInfo(null);
      setShowingGame(false);
      gameActions.setStage5GroupInfo(null);
      return;
    }
    if (groupDocData.state === GroupState.Initializing) {
      return;
    }
    if (groupDocData.state === GroupState.Finished) {
      return;
    }
    if (showingResult) {
      return;
    }
    setShowingGame(true);
  }, [groupDocData, showingGame, stageState.finished, groupDocDataLoading]);

  const groupStart = React.useCallback(() => {
    setShowingGame(true);
  }, [setShowingGame]);

  React.useEffect(() => {
    if (groupDocData?.state === GroupState.Finished && !stageState.finished) {
      if (eventId && user?.uid) {
        finishGame(eventId, user.uid);
      }
      dispatch(gameActions.setHasStageFinished({ stage: 5, finished: true }));
    } else if (
      groupDocData?.state !== GroupState.Finished &&
      stageState.finished
    ) {
      if (eventId && user?.uid) {
        finishGame(eventId, user.uid);
      }
      dispatch(gameActions.setHasStageFinished({ stage: 5, finished: false }));
    }
  }, [dispatch, groupDocData?.state, stageState.finished]);

  const showingResult = stageState.finished;
  return (
    <StageManager stageId={5} noCountDown noRecordStart>
      {showingResult ? (
        <Result
          groupDocData={userDocData}
          memberDatas={memberDatas}
          searchId={groupInfo?.searchId ?? 0}
        />
      ) : (
        <>
          {showingGame ? (
            <GameManager
              groupDocData={userDocData}
              memberDatas={memberDatas}
              searchId={groupInfo?.searchId ?? 0}
            />
          ) : (
            <GroupManager
              groupDocData={groupDocData}
              onGroupStart={groupStart}
              memberDatas={memberDatas}
              onReloadGroupMembers={onReloadGroupMembers}
            />
          )}
        </>
      )}
    </StageManager>
  );
};

export default Stage5;
