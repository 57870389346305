import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
  useStartProblemMutation,
  useSubmitAnswer5Mutation,
} from '../../../../api/__generated__/stage5.generated';
import { Stage5UserDocument } from '../../../../api/stage5';
import { shiftToNextProblem } from '../../../../lib/problems/stage5Checker';
import { useStage5Problem } from '../../../../lib/problems/stage5problems';
import { useRetryableMutationWithUI } from '../../../../lib/useRetryableMutationWithUI';
import { useSetLoadingState } from '../../../../redux/actions/appActions';
import { timeActions } from '../../../../redux/actions/timeActions';
import { useErrorState } from '../../../../redux/selectors/appSelectors';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import {
  useEventId,
  useStage5GroupInfo,
  useStage5UserFlags,
} from '../../../../redux/selectors/gameSelectors';
import {
  useRemainingTime,
  useTimerDuration,
  useTimerStartTime,
} from '../../../../redux/selectors/timeSelectors';
import { GroupState } from '../../../../types';
import CommonBG from '../../../uiElements/CommonBG';
import GameAnswerer from './GameAnswerer';
import GameBeforeStart from './GameBeforeStart';
import GamePresenter from './GamePresenter';
import { GroupMemberItem } from './GroupInfo';

interface GameManagerProps {
  groupDocData?: Stage5UserDocument;
  memberDatas: GroupMemberItem[];
  searchId: number;
}

const PROBLEM_DURATION = 20;

interface EventInput {
  eventId: string;
  groupId: string;
}

const useExtractedGroupDocData = (
  groupDocData: Stage5UserDocument | undefined
) => {
  const { isPresenter } = useStage5UserFlags(groupDocData);
  const groupState = groupDocData?.state;
  const [prevGroupState, setPrevGroupState] =
    React.useState<GroupState | undefined>(undefined);
  React.useEffect(() => setPrevGroupState(groupState), [groupState]);
  const startMillisTimeOnServer = groupDocData?.problemStartedAt?.toMillis();
  const problemId = groupDocData?.currentProblemId ?? 1;
  const problemCount = groupDocData?.currentProblemNumber ?? 0;

  const problem = useStage5Problem(problemId, problemCount);
  return React.useMemo(
    () => ({
      isPresenter,
      groupState,
      prevGroupState,
      startMillisTimeOnServer,
      problemId,
      problem,
    }),
    [
      isPresenter,
      groupState,
      prevGroupState,
      startMillisTimeOnServer,
      problemId,
      problem,
    ]
  );
};

const useStageStartCallback = (
  eventInput: EventInput | undefined,
  isPresenter: boolean
) => {
  const [startProblem] = useRetryableMutationWithUI(useStartProblemMutation, {
    hookOptions: {},
    loading: { options: { text: '問題を開始しています...' } },
  });
  return React.useCallback(() => {
    if (!eventInput) {
      return;
    }
    startProblem({
      variables: {
        input: eventInput,
      },
    });
  }, [eventInput, isPresenter, startProblem]);
};

const useSubmitAnswerCallback = (
  eventInput: EventInput | undefined,
  problemId: number | undefined
) => {
  const [submitAnswer] = useRetryableMutationWithUI(useSubmitAnswer5Mutation, {
    hookOptions: {},
    loading: { disabled: true },
  });
  return React.useCallback(
    (answer: number | null) => {
      if (problemId === undefined || eventInput === undefined) {
        return;
      }
      submitAnswer({
        variables: {
          input: {
            ...eventInput,
            answer: '' + (answer ?? '-'),
            problemId,
          },
        },
      });
    },
    [eventInput, problemId, submitAnswer]
  );
};

const useStage5TimerEffect = (
  startMillisTimeOnServer: number | undefined,
  groupState: GroupState | undefined
) => {
  const dispatch = useDispatch();
  const timerStartTime = useTimerStartTime();
  const timerDuration = useTimerDuration();
  React.useEffect(() => {
    if (startMillisTimeOnServer === undefined) return;
    const startTimeOnServer = startMillisTimeOnServer / 1000;
    /*if (
      timerDuration === PROBLEM_DURATION &&
      timerStartTime === startTimeOnServer
    ) {
      return;
    }*/
    if (groupState === GroupState.Ongoing) {
      dispatch(
        timeActions.setTimer({
          startTimeOnServer,
          duration: PROBLEM_DURATION,
        })
      );
    }
  }, [
    dispatch,
    timerDuration,
    timerStartTime,
    startMillisTimeOnServer,
    groupState,
  ]);
};

const GameManager: React.FC<GameManagerProps> = ({
  groupDocData,
  memberDatas,
  searchId,
}) => {
  const user = useCurrentUser();
  const eventId = useEventId();
  const groupInfo = useStage5GroupInfo();
  const groupId = groupInfo?.groupId;
  const eventInput: EventInput | undefined = React.useMemo(
    () =>
      groupId !== undefined && eventId !== null
        ? { groupId, eventId }
        : undefined,
    [eventId, groupId]
  );

  const errorOverlayState = useErrorState();
  const setLoadingState = useSetLoadingState();
  const [skipped, setSkipped] = React.useState(false);
  const remainingTime = useRemainingTime();
  const {
    isPresenter,
    groupState,
    prevGroupState,
    startMillisTimeOnServer,
    problemId,
    problem,
  } = useExtractedGroupDocData(groupDocData);
  const [inactiveMemberDatas, setInactiveMemberDatas] = React.useState<
    GroupMemberItem[]
  >([]);

  const dispatch = useDispatch();
  const startCallback = useStageStartCallback(eventInput, isPresenter);
  useStage5TimerEffect(startMillisTimeOnServer, groupState);
  const onStageStart = () => {
    dispatch(timeActions.clearTimer());
    startCallback();
  };

  const onSubmitAnswer = useSubmitAnswerCallback(eventInput, problemId);

  // Timer の設定

  // Ongoing
  React.useEffect(() => {
    if (groupState !== GroupState.Ongoing) return;
    if (remainingTime === null) return;
    if (!skipped && remainingTime > 0) return;
    if (isPresenter) {
      setLoadingState({
        visible: true,
        text: '解答を締め切っています...',
      });
    } else {
      setLoadingState({
        visible: true,
        text: '判定中...',
      });
    }
    setTimeout(
      () => {
        setLoadingState({
          visible: false,
        });
        setSkipped(false);
      },
      skipped ? 0 : 5 * 1000
    );
  }, [groupState, isPresenter, remainingTime, setLoadingState]);

  // Showinganswer
  React.useEffect(() => {
    if (groupState !== GroupState.Showinganswer) return;
    if (isPresenter) {
      setLoadingState({
        visible: true,
        text: '判定中...',
      });
    } else {
      setLoadingState({
        visible: false,
      });
    }
  }, [groupState, isPresenter, setLoadingState]);

  // Showinganswer からの遷移
  React.useEffect(() => {
    if (groupState === prevGroupState) return;
    if (prevGroupState !== GroupState.Showinganswer) return;
    setLoadingState({
      visible: false,
    });
  }, [groupState, prevGroupState, setLoadingState]);

  // Unmount
  React.useEffect(() => {
    return () => {
      setLoadingState({
        visible: false,
      });
    };
  }, [setLoadingState]);

  if (groupDocData === undefined) {
    return (
      <CommonBG>
        <div>Loading...</div>
      </CommonBG>
    );
  }

  switch (groupState) {
    case GroupState.Preparetostart:
      return (
        <GameBeforeStart
          groupDocData={groupDocData}
          onStart={onStageStart}
          memberDatas={memberDatas}
          searchId={searchId}
        />
      );
    case GroupState.Ongoing:
    case GroupState.Showinganswer:
      return isPresenter ? (
        <GamePresenter problem={problem} searchId={searchId} />
      ) : (
        <GameAnswerer
          problem={problem}
          searchId={searchId}
          showingAnswer={groupState === GroupState.Showinganswer}
          onChangeAnswer={onSubmitAnswer}
        />
      );
    default:
      return <></>;
  }
};

export default GameManager;
