import { Data } from 'react-firebase-hooks/firestore/dist/firestore/types';

import { Game5Summary } from '../../api/firestoreTypes';
import { GroupMemberItem } from '../../components/eventProvider/stagePages/stage5/GroupInfo';
import { SingleSet } from '../../components/eventProvider/stagePages/stage5/ResultContents';
import {
  NUM_OF_QUESTIONS_FOUR,
  NUM_OF_QUESTIONS_THREE,
  problemForFour,
  stage5Answers,
  stage5problems,
} from './stage5problems';

const problemForThree = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
];

export const summaryToAnswer = (
  memberDatas: GroupMemberItem[],
  summary: Data<Game5Summary, 'uid', ''>[],
  uid: string
) => {
  const memberSummary = memberDatas.map(member => {
    const ret = Object.fromEntries(
      summary
        ?.find(sum => sum.uid === member.uid)
        ?.answers.map(ans => [ans.problemId, ans.answer]) ?? []
    );
    return ret;
  });

  const mySummary = Object.fromEntries(
    summary
      .find(sum => sum.uid === uid)
      ?.answers.map(ans => [ans.problemId, ans.answer]) ?? []
  );
  if (!mySummary) {
    return [];
  }
  console.log(mySummary, memberSummary);

  if (memberDatas.length === 3) {
    return [0, 1, 2].map(e => {
      const isPresenter = memberDatas[e]?.uid === uid;
      const numOfQuestions = NUM_OF_QUESTIONS_THREE;
      const problemSet = problemForThree.slice(
        numOfQuestions * e,
        numOfQuestions + numOfQuestions * e
      );
      return {
        isPresenter,
        answers: isPresenter
          ? problemSet.map(i =>
              memberSummary.reduce(
                (a, b) => a + (b[i] == '' + stage5Answers[i - 1] ? 1 : 0),
                0
              )
            )
          : problemSet
              .map(i => mySummary[i] ?? '-')
              .map(e => (e == '-' ? -1 : parseInt(e))),
      };
    });
  } else {
    return [0, 1, 2, 3].map(e => {
      const isPresenter = memberDatas[e]?.uid === uid;
      const numOfQuestions = NUM_OF_QUESTIONS_FOUR;
      const problemSet = problemForFour.slice(
        numOfQuestions * e,
        numOfQuestions + numOfQuestions * e
      );
      return {
        isPresenter,
        answers: isPresenter
          ? problemSet.map(i =>
              memberSummary.reduce(
                (a, b) => a + (b[i] == '' + stage5Answers[i - 1] ? 1 : 0),
                0
              )
            )
          : problemSet
              .map(i => mySummary[i] ?? '-')
              .map(e => (e == '-' ? -1 : parseInt(e))),
      };
    });
  }
};
const arrayChunk = ([...array], size = 1) => {
  return array.reduce(
    (acc, value, index) =>
      index % size ? acc : [...acc, array.slice(index, index + size)],
    []
  );
};

const getFixedProblem = (memberCount: number) => {
  if (memberCount == 4) {
    const problem4 = problemForFour.map(single => {
      return stage5problems[single - 1];
    });
    return arrayChunk(problem4, NUM_OF_QUESTIONS_FOUR);
  } else if (memberCount == 3) {
    const problem3 = problemForThree.map(single => {
      return stage5problems[single - 1];
    });
    return arrayChunk(problem3, NUM_OF_QUESTIONS_THREE);
  }
  return [];
};

export type ResultAnswerRaw = {
  isPresenter: boolean;
  answers: number[];
}[];

export const getProblemAndAnswer = (
  answer: ResultAnswerRaw,
  memberCount: number
): SingleSet[] => {
  const fixedProblem = getFixedProblem(memberCount);
  if (answer.length === 0) {
    return [];
  }
  let count = 0;
  const rr = fixedProblem.map((problems, s) => {
    const r = problems.map((singleProblem, p) => {
      if (answer[s].isPresenter) {
        // 出題者
        return {
          images: singleProblem,
          correctUsers: answer[s].answers[p],
        };
      } else {
        // 解答者
        // 正しい答え
        const correctAnswer =
          memberCount == 4
            ? stage5Answers[problemForFour[count + p] - 1]
            : stage5Answers[count + p];

        return {
          images: singleProblem,
          answer: answer[s].answers[p],
          answerCorrect: correctAnswer == answer[s].answers[p],
        };
      }
    });
    count += r.length;
    return { answers: r, isPresenter: answer[s].isPresenter };
  });

  return rr;
};

export const calcScore = (
  problemAndAnswer: SingleSet[],
  memberCount: number
) => {
  return problemAndAnswer.reduce(
    (prev, curr) => {
      const r = curr.answers.reduce(
        (prev2, curr2) => {
          if (curr.isPresenter) {
            if (curr2.correctUsers == undefined) {
              return {
                score: prev2.score,
                maxScore: prev2.maxScore + memberCount - 1,
              };
            }
            return {
              score: prev2.score + curr2.correctUsers,
              maxScore: prev2.maxScore + memberCount - 1,
            };
          } else {
            return {
              score: prev2.score + (curr2.answerCorrect ? 1 : 0),
              maxScore: prev2.maxScore + 1,
            };
          }
        },
        { score: 0, maxScore: 0 }
      );

      return {
        score: prev.score + r.score,
        maxScore: prev.maxScore + r.maxScore,
      };
    },
    { score: 0, maxScore: 0 }
  );
};
