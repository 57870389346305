import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useStartGroupMutation } from '../../../../api/__generated__/stage5.generated';
import { Stage5GroupDocument, useStage5GroupDoc } from '../../../../api/stage5';
import { usePrevious } from '../../../../lib/usePrevious';
import { useRetryableMutationWithUI } from '../../../../lib/useRetryableMutationWithUI';
import { appActions } from '../../../../redux/actions/appActions';
import { gameActions } from '../../../../redux/actions/gameActions';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import {
  useEventId,
  useStage5GroupInfo,
} from '../../../../redux/selectors/gameSelectors';
import { StageManagerPhase } from '../../stageProviders/StageManager';
import GroupIdInput from './GroupIdInput';
import GroupInfo, { GroupMemberItem } from './GroupInfo';
import GroupMenu from './GroupMenu';

interface GroupManagerProps {
  groupDocData?: Stage5GroupDocument;
  onGroupStart: () => void;
  memberDatas: GroupMemberItem[];
  onReloadGroupMembers: () => void;
}
const GroupManager: React.FC<GroupManagerProps> = props => {
  const dispatch = useDispatch();
  const onGroupStartInherited = props.onGroupStart;
  const { groupDocData } = props;
  const eventId = useEventId();
  const groupInfo = useStage5GroupInfo();

  const [groupDoc] = useStage5GroupDoc(eventId, groupInfo?.groupId);
  const [selfLeave, setSelfLeave] = React.useState<boolean>();

  const prevDoc = usePrevious(groupDoc);
  const user = useCurrentUser();

  React.useEffect(() => {
    if (groupDoc == null && selfLeave) {
      setSelfLeave(false);
      return;
    }
    if (
      !groupDoc?.exists() &&
      prevDoc?.exists() &&
      (prevDoc.data() as Stage5GroupDocument)?.createdBy !== user?.uid
    ) {
      if (selfLeave) {
        setSelfLeave(false);
        return;
      }
      dispatch(
        appActions.setErrorOverlayState({
          errorType: 'CommonError',
          message:
            'グループが解散されました。グループの作成、または参加からやり直してください。',
          retryButtonText: 'OK',
        })
      );
    }
  }, [groupDoc, prevDoc]);

  const [showingJoin, setShowingJoin] = React.useState<boolean>(false);

  const onStartGroupCompleted = React.useCallback(() => {
    onGroupStartInherited();
  }, [onGroupStartInherited]);

  const [startGroup] = useRetryableMutationWithUI(useStartGroupMutation, {
    hookOptions: { onCompleted: onStartGroupCompleted },
    loading: { options: { text: 'ゲーム開始処理中...' } },
  });

  const onGroupStart = React.useCallback(() => {
    const groupId = groupInfo?.groupId;
    if (!groupId || !eventId) {
      return;
    }

    startGroup({
      variables: {
        input: {
          eventId,
          groupId,
        },
      },
    });
  }, [eventId, groupInfo, startGroup]);

  const onBackToInstruction = React.useCallback(() => {
    dispatch(
      gameActions.setStageManagerState({ phase: StageManagerPhase.INSTRUCTION })
    );
  }, [dispatch]);

  const onBackFromJoin = React.useCallback(() => {
    setShowingJoin(false);
  }, []);

  return (
    <>
      {groupInfo !== null && groupDoc !== undefined && groupDoc.exists() ? (
        <GroupInfo
          groupDocData={groupDocData}
          onGameStart={onGroupStart}
          memberDatas={props.memberDatas}
          onReloadGroupMembers={props.onReloadGroupMembers}
          setSelfLeave={setSelfLeave}
        />
      ) : showingJoin ? (
        <GroupIdInput onBack={onBackFromJoin} />
      ) : (
        <GroupMenu
          onJoinInput={() => setShowingJoin(true)}
          onBack={onBackToInstruction}
        />
      )}
    </>
  );
};

export default GroupManager;
