import { TextWindow } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import { Stage5Problem } from '../../../../lib/problems/stage5problems';
import Colors, { colorWithAlpha } from '../../../../styles/colors';

interface GamePresenterViewProps {
  problem: Stage5Problem;
}

const GamePresenterView: React.FC<GamePresenterViewProps> = ({ problem }) => {
  return (
    <GamePresenterViewWrapper>
      <QNum>Q{problem.count}</QNum>
      <AnswererText bracket>
        <MainText>{problem.text.presenter.main}</MainText>
        <SubText>{problem.text.presenter.sub}</SubText>
      </AnswererText>
      <QWindow imgSrc={problem.imageSrc[0]} />
    </GamePresenterViewWrapper>
  );
};

const GamePresenterViewWrapper = styled.div``;

const QNum = styled.div`
  position: absolute;
  top: 8rem;
  left: calc(50% - 50rem);
  height: 7rem;
  width: 7rem;
  box-sizing: border-box;
  color: ${Colors.white};
  background-color: ${Colors.gray8};
  font-size: 2.5rem;
  line-height: 7rem;
  text-align: center;
`;

const AnswererText = styled(TextWindow)`
  position: absolute;
  top: 8rem;
  left: calc(50% - 50rem + 7rem + 1rem);
  height: 7rem;
  width: 92rem;
  line-height: 7rem;
  text-align: left;
  padding: 0 3rem;
  box-sizing: border-box;
  background-color: ${colorWithAlpha('gray4', 0.25)};
  font-weight: bold;
`;

const MainText = styled.span``;
const SubText = styled.span`
  font-size: 0.65em;
`;

interface QWindowProps {
  imgSrc: string;
}

const QWindow = styled.div`
  width: 90%;
  height: calc(100% - 25rem);

  position: absolute;
  top: 17rem;
  left: 50%;
  transform: translateX(-50%);

  background-image: url(${(p: QWindowProps) => p.imgSrc});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export default GamePresenterView;
