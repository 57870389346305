import * as React from 'react';

import { Stage5Problem } from '../../../../lib/problems/stage5problems';
import { useRemainingTime } from '../../../../redux/selectors/timeSelectors';
import CommonBG from '../../../uiElements/CommonBG';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';
import GameAnswererView from './GameAnswererView';
import GroupIdIndicator from './GroupIdIndicator';

interface GameAnswererProps {
  problem: Stage5Problem;
  showingAnswer: boolean;
  searchId: number;
  onChangeAnswer: (answer: number | null) => void;
}

const GameAnswerer: React.FC<GameAnswererProps> = ({
  problem,
  searchId,
  showingAnswer,
  onChangeAnswer,
}) => {
  const [currentAnswer, setCurrentAnswer] = React.useState<number | null>(null);
  const remainingTime = useRemainingTime() ?? 0;

  const updateAnswer = (ans: number) => {
    if (showingAnswer || remainingTime < 1) {
      return;
    }
    setCurrentAnswer(ans);
  };

  React.useEffect(() => {
    onChangeAnswer(currentAnswer);
  }, [currentAnswer, onChangeAnswer]);

  return (
    <CommonBG>
      <TimeGauge />
      <GameAnswererView
        onUpdate={updateAnswer}
        currentAnswer={currentAnswer}
        showingAnswer={showingAnswer}
        problem={problem}
      />
      <GroupIdIndicator searchId={searchId} />
    </CommonBG>
  );
};

export default GameAnswerer;
